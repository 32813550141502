.app {
  text-align: center;
}

.profilePicture {  
  width: 100%;
  transform: scale(1, 1);
  transition: 1s;
}

.profilePicture:hover {
  transform :scale(0.9, 0.9);
}

.username { 
  font-family: Roboto;
  font-weight: normal;
  color: white;
  font-size: 3em;
  margin: 0;
  margin-top: 10;
  transform: scale(1, 1);
  transition: 1s;
}

.username:hover {
  transform :scale(0.9, 0.9);
}